// ----------------------------------------------------------------------

const ROOTS = {
  AUTH: '/auth',
  DASHBOARD: '/dashboard',
  EQUIPMENTS: '/equipment',
  REPORTS: '/report',
  USERS: '/users',
  SITES: '/sites',
  SETTINGS: '/settings',
  SUPPORT: '/support',
  ADMIN: '/admin',
};

// ----------------------------------------------------------------------

export const paths = {
  // AUTH
  auth: {
    login: `${ROOTS.AUTH}/login`,
    register: `${ROOTS.AUTH}/register`,
    retrievePassword: `${ROOTS.AUTH}/retrievePassword`,
    emailValidation: `${ROOTS.AUTH}/emailValidation`,
  },
  // DASHBOARD
  dashboard: {
    root: ROOTS.DASHBOARD,
  },
  // EQUIPMENTS
  equipment: {
    root: ROOTS.EQUIPMENTS,
    new: `${ROOTS.EQUIPMENTS}/new`,
    duplicate: (id: string | number) =>
      `${ROOTS.EQUIPMENTS}/new?duplicateFrom=${id}`,
    details: (id: string | number) => `${ROOTS.EQUIPMENTS}/${id}`,
    edit: (id: string | number) => `${ROOTS.EQUIPMENTS}/${id}/edit`,
    tags: `${ROOTS.EQUIPMENTS}/tags`,
  },
  //REPORT
  reports: {
    root: ROOTS.REPORTS,
    view: `${ROOTS.REPORTS}/view`,
  },
  //USERS
  users: {
    root: ROOTS.USERS,
    new: `${ROOTS.USERS}/new`,
    view: (id: string | number) => `${ROOTS.USERS}/${id}`,
    edit: (id: string | number) => `${ROOTS.USERS}/${id}/edit`,
  },
  //LOCATION
  sites: {
    root: ROOTS.SITES,
  },
  //SETTINGS
  settings: {
    user: ROOTS.SETTINGS,
    organization: `${ROOTS.SETTINGS}/organization`,
  },
  support: ROOTS.SUPPORT,
  errors: {
    notFound404: '/404',
  },
  //ADMIN
  admin: {
    import: `${ROOTS.ADMIN}/import`,
    actions: `${ROOTS.ADMIN}/actions`,
  },
};
