import { paths } from 'src/routes/paths';

// API
// ----------------------------------------------------------------------

export const HOST_API = import.meta.env.VITE_HOST_API;
export const ASSETS_API = import.meta.env.VITE_ASSETS_API;
export const AMPLITUDE_API_KEY = import.meta.env.VITE_AMPLITUDE_API_KEY;
export const AMPLITUDE_TUNNEL = 'https://amplitude.geartracker.net';

// ROOT PATH AFTER LOGIN SUCCESSFUL
export const PATH_AFTER_LOGIN = paths.dashboard.root; // as '/dashboard'
export const PATH_AFTER_REGISTER = paths.auth.emailValidation;

// Sentry
export const SENTRY_DSN = import.meta.env.VITE_SENTRY_DSN;
export const SENTRY_ENV = import.meta.env.VITE_SENTRY_ENV;
export const SENTRY_TUNNEL = 'https://sentry.geartracker.net';

//super admin
export const SUPER_ADMIN_ORG_ID = import.meta.env.VITE_ADMIN_ORG_ID;

// auth storage key
export const TOKEN_STORAGE_KEY = 'accessToken';

// mode
export const APP_MODE = import.meta.env.MODE as
  | 'development'
  | 'staging'
  | 'production';
